import React from 'react';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCat, faChampagneGlasses, faCloudShowersHeavy,
    faComments, faHandsHolding, faHeart, faHeartCirclePlus,
    faIcons, faMicrophoneLines, faMusic, faPlaneDeparture, faStar
} from '@fortawesome/free-solid-svg-icons';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import ReactPlayer from 'react-player'

import {Col, Row, Image, FloatButton} from 'antd';

import './App.css';
import ImageCarouselAugust from "./ImageCarouselAugust";
import ImageCarouselJanuary from "./ImageCarouselJanuary";

const messageIcon = {
    icon: <FontAwesomeIcon icon={faComments}/>,
    iconStyle: {background: '#61afef', color: '#fff'}
};

const hobbyIcon = {
    icon: <FontAwesomeIcon icon={faIcons}/>,
    iconStyle: {background: '#98C379', color: '#fff'}
}
const hobbyIconBlue = {
    icon: <FontAwesomeIcon icon={faIcons}/>,
    iconStyle: {background: '#61AFEF', color: '#fff'}
}
const drinkGlassIcon = {
    icon: <FontAwesomeIcon icon={faChampagneGlasses}/>,
    iconStyle: {background: '#E45649', color: '#fff'}
}

const musicIcon = {
    icon: <FontAwesomeIcon icon={faMusic}/>,
    iconStyle: {background: '#98C379', color: '#fff'}
}

const heavyRainIcon = {
    icon: <FontAwesomeIcon icon={faCloudShowersHeavy}/>,
    iconStyle: {background: '#C678DD', color: '#fff'}
}

const starIcon = {
    icon: <FontAwesomeIcon icon={faStar}/>,
    iconStyle: {background: '#D19A66', color: '#fff'}
};

const planeDepartIcon = {
    icon: <FontAwesomeIcon icon={faPlaneDeparture}/>,
    iconStyle: {background: '#56B6C2', color: '#fff'}
}

const vHandIcon = {
    icon: <FontAwesomeIcon icon={faHandsHolding}/>,
    iconStyle: {background: '#D19A66', color: '#fff'}
}

const heartIcon = {
    icon: <FontAwesomeIcon icon={faHeart}/>,
    iconStyle: {background: '#E45649', color: '#fff'}
}

const catIcon = {
    icon: <FontAwesomeIcon icon={faCat}/>,
    iconStyle: {background: '#98C379', color: '#fff'}
}

const lovePlusIcon = {
    icon: <FontAwesomeIcon icon={faHeartCirclePlus}/>,
    iconStyle: {background: '#61afef', color: '#fff'}
}

const micIcon = {
    icon: <FontAwesomeIcon icon={faMicrophoneLines}/>,
    iconStyle: {background: '#C678DD', color: '#fff'}
}

function App() {
    const timeline = [
        {
            icon: messageIcon,
            date: '27/03/2022',
            title: 'First message',
            subtitle: "",
            contentStyle: {background: '#61afef', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #61afef'},
            desc:
                <Row justify="start"
                     gutter={24}
                >
                    <Col span={12}>
                        <Image width='100%' alt="example" src={"/img/first-message.jpg"}/>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Image width='100%' height='100%' alt="example" src={"/img/hai-mia.jpg"}/>
                        </Row>
                        <Row>
                            <p style={{padding: '4px'}}>
                                The start of something new. We started talking thanks to the recommendation of Hải Hobu.
                                I saw the photo he posted and asked about the gorgeous lady. Then BOOM, just a few days
                                passed.
                                <br/>
                                Mia texted.
                            </p>
                        </Row>
                    </Col>
                </Row>
        },
        {
            icon: hobbyIcon,
            date: '01/04/2022 - 25/04/2022',
            title: "Getting to know each other",
            contentStyle: {background: '#98C379', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #98C379'},
            desc: <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div>
                        <iframe style={{borderRadius: "12px"}}
                                src="https://open.spotify.com/embed/track/55dfWck2xFKfkd6sZNCS6e?utm_source=generator"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"
                                title="Le toi du moi"
                        >

                        </iframe>
                    </div>
                    <p>We talked and talked about us, about hobbies, music, movies, art, life. We had super long chats
                        and music listening sessions.
                        Progressively sharing joys and sorrow with each other
                        <br/>
                        day by day</p>
                </Col>
                <Col span={12}>
                    <Image width="100%" height={320} src={"/img/mia-1.jpg"}/>
                </Col>
                <Col span={12}>
                    <Image height={320} src={"/img/mia-2.jpg"}/>
                </Col>
            </Row>
        },
        {
            icon: drinkGlassIcon, date: '25/04/2022',
            contentStyle: {background: '#E45649', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #E45649'},
            title: "First date",
            desc:
                <div>
                    <Row
                        justify="start"
                        gutter={[24, 16]}
                    >
                        <Col span={12}
                        >
                            <Image
                                style={{maxHeight: '240px'}}
                                width='100%' height='100%' alt="example" src={"/img/first-met.jpg"}/>
                        </Col>
                        <Col span={12}>
                            <p style={{padding: '8px'}}>
                                The perfect night.
                                <br/><br/>
                                We drank, we talked, we walked. we kissed, we danced and intertwined deeply with our
                                hearts and body.
                                <br/>
                            </p>
                        </Col>
                        <Col span={24}>
                            <iframe style={{borderRadius: '12px'}}
                                    src="https://open.spotify.com/embed/track/7MtVPRGtZl6rPjMfLoI3Lh?utm_source=generator"
                                    width="100%" height="152" frameBorder="0" allowFullScreen=""
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                    title="fily"
                                    loading="lazy"/>
                        </Col>
                    </Row>
                </div>
        },
        {
            icon: starIcon,
            date: '30/04/2022 - 08/05/2022',
            title: "Our first getaway together",
            subtitle: "And many meetups that 2 weeks",
            contentStyle: {background: '#D19A66', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #D19A66'},
            desc: <Row gutter={24}>
                <Col span={12}>
                    <Image width='100%' height={240} src={"/img/first-getaway.jpg"} alt="first getaway"/>
                    <p>Visited the Museum of Arts. Later that week we also went to the arcade for
                        bowling, games and karaoke.
                        We also talked about exclusivity expectations and were
                        <br/>extremely happy together.
                    </p>
                </Col>
                <Col span={12}>
                    <Image width='100%' src={"/img/mia-blu-airport-1.jpg"} alt="first getaway"/>
                </Col>
            </Row>
        },
        {
            icon: musicIcon, date: '18/06/2022 - 19/06/2022', title: 'Our first concert together',
            subtitle: 'and defining the relationship',
            contentStyle: {background: '#98C379', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #98C379'},

            desc:
                <Row
                    gutter={24}>
                    <Col span={12}>
                        <Image width="100%" height={480} src={"/img/mia-school.jpeg"} alt="mia school"/>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Image width="100%" height={240} src={"/img/concert-1.jpg"} alt="mia school"/>
                        </Row>
                        <Row>
                            <p>Mia sooooooo cuteeeeeeeeeeeeeeeee!!!</p>
                            <p>Thành Phố Mơ Màng concert was a blasttt, we danced till our legs hurt.</p>
                            <p>We also sat down and have some serious discussions about us.</p>
                        </Row>
                    </Col>
                </Row>
        },
        {
            icon: heavyRainIcon,
            date: '22/07/2022 - 24/07/2022',
            title: 'Reality check',
            contentStyle: {background: '#b77acb', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #b77acb'},
            desc:
                <Row gutter={[24, 8]}>
                    <Col span={24}>
                        <iframe style={{borderRadius: '12px'}}
                                src="https://open.spotify.com/embed/track/25Up2vWWPlpYmdc0zpmDZq?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                title={"half as good"}
                                loading="lazy"/>
                    </Col>
                    <Col span={12}>
                        <p>I hurt you.</p>
                        <p>We had a lot of fun but also had our issues.</p>
                        <p>We broke up and stare at the night sky together.</p>
                        <p>But that gave us a chance to reflect and reconcile.</p>
                    </Col>
                    <Col span={12}>
                        <Image width="100%" height={240} src={"/img/post-breakup.jpg"} alt={"post breakup"}/>
                    </Col>
                </Row>
        },
        {
            icon: planeDepartIcon, title: "Mia visited Singapore with the sisterhood",
            date: "17/08/2022 - 28/08/2022",
            contentStyle: {background: '#56B6C2', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #56B6C2'},
            desc: <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ImageCarouselAugust/>
                </Col>
            </Row>
        },
        {
            icon: vHandIcon, title: "Meeting after a long period", date: '30/09/2022 - 02/10/2022',
            contentStyle: {background: '#D19A66', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #D19A66'},
            desc: <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Image src={"/img/lam-gom.jpg"}/>
                </Col>
                <Col span={12}>
                    <Image src={"/img/hu-tieu.jpg"}/>
                </Col>
                <Col span={12}>
                    <p>It was a whole long month of not seeing each other. We did pottery together, eating delicious
                        noodles. We took turns driving each other around on your scooter.
                        <br/>You gifted me this beautiful drawing.</p>
                </Col>
                <Col span={12}>
                    <Image src={"/img/mia-drawing.jpg"}/>
                </Col>
            </Row>
        },
        {
            icon: hobbyIconBlue, date: '20/10/2022 - 23/10/2022', title: "Boardgame, ballet and bittersweet",
            contentStyle: {background: '#61AFEF', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #61AFEF'},
            desc:
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Image width="100%" height={240} src={"/img/oct-1.jpg"}/>
                    </Col>
                    <Col span={12}>
                        <Image width="100%" height={240} src={"/img/oct-2.jpg"}/>
                    </Col>
                    <Col span={12}>
                        <Image width="100%" height={240} src={"/img/ballet.jpg"} alt="mia school"/>
                    </Col>
                    <Col span={12}>
                        <p>We met up with friends, played games, watched an opera, eat delicious food</p>
                        <p>But Mia also had to face some bittersweet moments</p>
                    </Col>
                </Row>
        },
        {
            icon: planeDepartIcon, title: "Baby was a busy bee", date: 'November 2022',
            contentStyle: {background: '#56B6C2', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #56B6C2'},
            desc: <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Image width="auto" height='auto' src={"/img/nov-1.jpg"}/>
                </Col>
                <Col span={12}>
                    <Image width="auto" height='auto' src={"/img/nov-2.jpg"}/>
                </Col>
                <Col span={24}>
                    <p>November was a busy period for Mia. But we still managed to meetup twice.</p>
                    <p>We went to the aquarium to meet some fishes, ate some delicious food (again). We ate at No Sign
                        Board crab place TWICE.</p>
                </Col>
            </Row>
        },
        {
            icon: heartIcon, title: "Dreamy December", date: 'December 2022',
            contentStyle: {background: '#E45649', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #E45649'},
            desc: <Row gutter={[16, 16]}>
                <Col span={24}>
                    <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/_4I3S4gRtCI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </Col>
                <Col span={24}>
                    <p>One of the best time of my life. Planned and anticipated for so long, our trip was a major
                        success.</p>
                    <p>Near the end of December we started working together on projects, celebrated Christmas and New
                        Year together as well.</p>
                </Col>
            </Row>
        },
        {
            icon: catIcon, date: 'January 2022 - Lunar New Year',
            title: "New Year new memories",
            contentStyle: {background: '#98C379', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #98C379'},
            desc: <Row>
                <Col>
                    <ImageCarouselJanuary/>
                </Col>
            </Row>
        },
        {
            icon: micIcon, title: "February from afar", date: '14/02/2022',
            contentStyle: {background: '#b77acb', color: '#fff'},
            arrowStyle: {borderRight: '7px solid #b77acb'},
            desc: <Row
                gutter={16}>
                <Col span={12}>
                    <Row gutter={[0, 48]}>
                        <Col span={24}>
                            <p>Valentine's Day is here but we don't have a chance to meet each other.
                                So hopefully you like this webpage I made as a gift.
                                <br/>For you, for us.
                                <br/>Wish us closeness, happiness and new days to come.
                            </p>
                        </Col>
                        <Col span={24}>
                            <div><AudioPlayer src="/spend-it-with-you.mp3"
                            />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <ReactPlayer
                        controls={true}
                        width='100%'
                        url="mia-garden.mp4"/>
                </Col>
            </Row>,
        },
        {
            icon: lovePlusIcon, date: 'now - future'
        }
    ];

    return (
        <div>
            <div className="App">
                <h3 style={{color: 'white'}}>A timeline of Mia <FontAwesomeIcon icon={faHeart}/> Blu</h3>
            </div>
            <FloatButton.BackTop/>

            <VerticalTimeline
            >
                {timeline.map((t, i) => {
                    return <VerticalTimelineElement
                        key={i}
                        className="vertical-timeline-element--work"
                        contentStyle={t.contentStyle}
                        contentArrowStyle={t.arrowStyle}
                        date={t.date}
                        {...t.icon}
                    >
                        {t.title ? <React.Fragment>
                            <h3 className="vertical-timeline-element-title">{t.title}</h3>
                            {t.subtitle && <h4 className="vertical-timeline-element-subtitle">{t.subtitle}</h4>}
                            {t.desc && <div style={{paddingTop: '1em'}}>{t.desc}</div>}
                        </React.Fragment> : undefined}
                    </VerticalTimelineElement>
                })}
            </VerticalTimeline>
        </div>
    );
}

export default App;
