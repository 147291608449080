import {Carousel, Col, Image, Row} from 'antd';
import React, {Component} from 'react';

const contentStyle = {
    margin: 0,
    height: '80px',
    color: '#fff',
    lineHeight: '40px',
    textAlign: 'center',
};

class ImageCarouselAugust extends Component {
    onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    render() {
        return (
            <Carousel autoplay={true} afterChange={this.onChange}>
                <div>
                    <div>
                        <Image width='auto' height={360} src={"/img/cny-1.jpeg"}/>
                        <div>
                            <h3 style={contentStyle}>Spring is here in Mia's smile and beautiful hair!</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <Image width="100%" height={360} src={"/img/cny-2.jpg"}/>
                            <h3 style={contentStyle}>Driving you everywhere means more time together!</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Row>
                            <Col span={12}>
                                <Image width="100%" height={360} src={"/img/cny-3.jpg"}/>
                            </Col>
                            <Col span={12}>
                                <Image width="100%" height={360} src={"/img/cny-4.jpg"}/>
                            </Col>
                        </Row>
                        <div>
                            <h3 style={contentStyle}>Just chilling at each other's houses</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{textAlign: 'center'}}>
                        <Image height={360} src={"/img/cny-5.jpg"}/>
                        <div>
                            <h3 style={contentStyle}>Our beautiful kiss under the lamppost (thanks Bin)</h3>
                        </div>
                    </div>
                </div>
            </Carousel>
        );
    }
}

export default ImageCarouselAugust;
