import {Carousel, Image} from 'antd';
import React, {Component} from 'react';

const contentStyle = {
    margin: 0,
    height: '80px',
    color: '#fff',
    lineHeight: '40px',
    textAlign: 'center',
};

class ImageCarouselAugust extends Component {
    onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    render() {
        return (
            <Carousel autoplay={true} afterChange={this.onChange}>
                <div>
                    <div style={{background: "#56B6C2"}}>
                        <Image width="100%" height={360} src={"/img/mia-sing-1.jpg"}/>
                        <div>
                            <h3 style={contentStyle}>Mia has arrived in Changi Airport</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{background: "#56B6C2"}}>
                        <Image width="100%" height={360} src={"/img/mia-sing-1-2.jpg"}/>
                        <div>
                            <h3 style={contentStyle}>Universal Studio, Ice Cream Museum, MBS, Merlion</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{background: "#56B6C2"}}>
                        <Image width="100%" height={360} src={"/img/mia-sing-1-3.jpg"}/>
                        <div>
                            <h3 style={contentStyle}>Fatty Cheong, waffles, noodles and mie</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{background: "#56B6C2"}}>
                        <Image width="100%" height={360} src={"/img/mia-sing-1-4.jpg"}/>
                        <div>
                            <h3 style={contentStyle}>Come back together for some more fun in Vietnam</h3>
                        </div>
                    </div>
                </div>
            </Carousel>
        );
    }
}

export default ImageCarouselAugust;
